
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import CompensationBox from '@/components/queries/molecules/CompensationBox.vue'
import SecurityBox from '@/components/queries/molecules/SecurityBox.vue'
import CompensarionBoxRegister from '@/components/queries/molecules/CompensationBoxRegister.vue';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({

  setup() {
      const authStore = useAuthStore();
      const authUser = authStore.user;
      const authUserCommunity = authStore.userCommunity;
      const route = useRoute();
      const router = useRouter();
      const showCompensationBox = ref<boolean>(false);
      const showSecurityBox = ref<boolean>(false);
      const showRegister = ref<boolean>(false)
      const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
      const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
      const actionResponse = ref<string>('')
      const itemServiceId = ref<number>(0)
      const typeModal = ref<string>('')
      const committeData = [
          { id: 1, figure: 'queries/account-lock', title: 'Nómina de Trabajadores', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
          { id: 2, figure: 'queries/account-lock', title: 'Caja de Compensación', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
          { id: 3, figure: 'queries/account-lock', title: 'Mutual de Seguridad', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
      ]
      const breadcrumbRoutes = [
          { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
          { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
          { path: `/community/${communityId.value}/block/${blockId.value}/queries/committe`, name: 'Administración y comité' },
          { path: ``, name: 'Personal de servicio' },
      ]
      async function goCommitteOption(index: number) {
          switch (index) {
              case 1:
                  router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/committe/personal-service/workers` });
                  break;
              case 2:
                  showCompensationBox.value = true
                  break;
              case 3:
                  showSecurityBox.value = true
                  break;

          }
      }

      const back = () => {
          router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/committe` });
      }

      const actionServices = (action: any, itemId: any, type: any) => {
          closeModal()
          typeModal.value = type
          actionResponse.value = action
          itemServiceId.value = itemId
          showRegister.value = true
      }


      const closeModal = () => {
          showCompensationBox.value = false
          showSecurityBox.value = false
          showRegister.value = false

      }
      return {
          committeData,
          goCommitteOption,
          back,
          showCompensationBox,
          showSecurityBox,
          closeModal,
          actionServices,
          showRegister,
          actionResponse,
          itemServiceId,
          typeModal,
          breadcrumbRoutes,
          authUser,
          authUserCommunity
      }
  },
  components: {
      CardEmergency, CompensationBox, SecurityBox, CompensarionBoxRegister
  }
})
