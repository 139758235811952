
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommunityService } from '@/services/communities/CommunityServices';
import { BlockInfo } from '@/services/communities/types';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import TableBackground from '@/components/general/molecules/TableBackground.vue';
import Modal from '@/components/general/molecules/Modal.vue';
import TextField from '@/components/general/atoms/TextField.vue';
import { useAuthStore } from '@/stores/auth'
import Header from '@/components/general/molecules/Header.vue';
export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const updateKey = ref(0);
        const route = useRoute();
        const router = useRouter();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const block = ref<BlockInfo>()
        const showModalAdd = ref<boolean>(false);
        const date = ref<string>('')
        const loading = ref<boolean>(true);
        const isFieldsFilled = computed(() => {
            return block.value?.name && block.value?.address && block.value?.totalFloors && block.value?.totalUnits
        });
        const columns = ref([{ name: 'name', label: 'Nombre del edificio' }, { name: 'companyName', label: 'Nombre compañía' }, { name: 'constructionCompany', label: 'Compañía de construcción' }, { name: 'address', label: 'Dirección' },
        { name: 'constructionYear', label: 'Año de construcción' }, { name: 'gasType', label: 'Tipo de gas' }, { name: 'realStateAgency', label: 'Agencia' },
        { name: 'rut', label: 'Rut' }, { name: 'totalCommercialPremises', label: 'Total de edificios comerciales' }, { name: 'municipalAcceptanceDate', label: 'Fecha de recepción municipal' }, { name: 'totalFloors', label: 'Total de pisos' },
        { name: 'totalParkingSpots', label: 'Total de estacionamientos' }, { name: 'totalUndegroundLevels', label: 'Total de pisos subterráneos' }, { name: 'totalUnits', label: 'Total de unidades' },
        { name: 'totalVisitorParkingSpots', label: 'Total de estacionamientos de visitantes' }, { name: 'totalWarehouseSpaces', label: 'Total de espacios de almacenamiento' },]);
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: ``, name: 'Antecedentes del edificio' },
        ]

        async function getBlockInfo() {
            try {
                await CommunityServices.getBlockById(communityId.value, blockId.value)
                    .then((response) => {
                        if (!response.data) {
                            block.value = undefined;
                        }
                        else {
                            block.value = response.data;
                            if (block.value?.municipalAcceptanceDate) {
                                let [dateString, timeString] = block.value.municipalAcceptanceDate.split('T');
                                let [year, month, day] = dateString.split('-').map(Number);
                                let [hour, minute, second] = timeString.split(':').map(Number);
                                let expirationDate = new Date(year, month - 1, day, hour, minute, second);
                                console.log(expirationDate.toLocaleString());
                                let isoDate = expirationDate.getFullYear() + '-' +
                                    (expirationDate.getMonth() + 1).toString().padStart(2, '0') + '-' +
                                    expirationDate.getDate().toString().padStart(2, '0') + 'T' +
                                    expirationDate.getHours().toString().padStart(2, '0') + ':' +
                                    expirationDate.getMinutes().toString().padStart(2, '0') + ':' +
                                    expirationDate.getSeconds().toString().padStart(2, '0');
                                console.log(isoDate);
                                date.value = isoDate.slice(0, 10);
                            }
                        }
                    })
            }
            catch (error) {
                console.log(error);
            }
            loading.value = false;
        }
        getBlockInfo();
        function updateTable() {
            showModalAdd.value = true
        }
        async function saveUpdateTable() {
            if (block.value) {
                const item = {
                    totalVisitorParkingSpots: block.value.totalVisitorParkingSpots,
                    totalParkingSpots: block.value.totalParkingSpots,
                    totalWarehouseSpaces: block.value.totalWarehouseSpaces,
                    totalCommercialPremises: block.value.totalCommercialPremises,
                    totalUndegroundLevels: block.value.totalUndegroundLevels,
                    gasType: block.value.gasType,
                    municipalAcceptanceDate: new Date(date.value),
                    constructionYear: block.value.constructionYear,
                    structuralCalculations: block.value.structuralCalculations,
                    constructionCompany: block.value.constructionCompany,
                    realStateAgency: block.value.realStateAgency,
                    rut: block.value.rut,
                    companyName: block.value.companyName,
                    communityId: communityId.value,
                    blockId: blockId.value,
                    name: block.value.name,
                    address: block.value.address,
                    totalFloors: block.value.totalFloors,
                    totalUnits: block.value.totalUnits,
                };

                await CommunityServices.updateCommunityBlock(communityId.value, blockId.value, item);
                showModalAdd.value = false
                getBlockInfo();
                forceUpdate();
            }

        }
        function forceUpdate() {
            updateKey.value += 1;
        }
        return {
            updateTable,
            saveUpdateTable,
            forceUpdate,
            updateKey,
            date,
            block,
            columns,
            showModalAdd,
            isFieldsFilled,
            breadcrumbRoutes,
            loading,
            authUser,
            authUserCommunity


        }
    },
    components: { TableBackground, ComponentCard, Modal, TextField, Header },
})
