import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col h-full"
}
const _hoisted_2 = { class: "flex flex-col md:flex-row h-full" }
const _hoisted_3 = { class: "flex h-full w-full md:w-full p-4" }
const _hoisted_4 = { class: "flex-col md:flex-row w-full md:w-full p-4 flex flex-wrap justify-center items-center h-full gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!
  const _component_AdminBox = _resolveComponent("AdminBox")!
  const _component_CompensarionBoxRegister = _resolveComponent("CompensarionBoxRegister")!

  return (_ctx.authUserCommunity?.userRole === 2 ||
    _ctx.authUserCommunity?.userRole === 3 ||
    _ctx.authUser?.isSuperuser
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header, {
          breadcrumbRoutes: _ctx.breadcrumbRoutes,
          hasBack: true
        }, null, 8, ["breadcrumbRoutes"]),
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("article", _hoisted_3, [
            _createElementVNode("section", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.committeData, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "flex-1 p-2 flex h-2/5 gap-4"
                }, [
                  _createVNode(_component_CardEmergency, {
                    size: 20,
                    styleCard: item.styleCard,
                    class: "cursor-pointer flex-grow",
                    figure: item.figure,
                    title: item.title,
                    onClick: ($event: any) => (_ctx.goCommitteOption(item.id))
                  }, null, 8, ["styleCard", "figure", "title", "onClick"])
                ]))
              }), 128))
            ]),
            (_ctx.showAdminBox)
              ? (_openBlock(), _createBlock(_component_AdminBox, {
                  key: 0,
                  onActionServices: _ctx.actionServices,
                  visible: _ctx.showAdminBox,
                  onCloseModal: _ctx.closeModal
                }, null, 8, ["onActionServices", "visible", "onCloseModal"]))
              : _createCommentVNode("", true),
            (_ctx.showRegister)
              ? (_openBlock(), _createBlock(_component_CompensarionBoxRegister, {
                  key: 1,
                  visible: _ctx.showRegister,
                  onCloseModal: _ctx.closeModal,
                  action: _ctx.actionResponse,
                  itemServiceId: _ctx.itemServiceId,
                  typeModal: _ctx.typeModal
                }, null, 8, ["visible", "onCloseModal", "action", "itemServiceId", "typeModal"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}