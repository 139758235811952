import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex justify-end" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex items-center justify-between text-white" }
const _hoisted_4 = {
  key: 0,
  class: "px-4 py-2 text-gray-700 overflow-auto"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "px-4 py-4 text-gray-700 overflow-auto"
}
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "flex-1 whitespace-pre-line" }
const _hoisted_9 = { class: "flex justify-end" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full max-w-${_ctx.width} mx-auto`)
  }, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.editable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "flex px-4 py-2 mb-4 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add')))
            }, " Agregar "))
          : _createCommentVNode("", true)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "relative bg-white rounded-lg shadow-md overflow-hidden mb-2",
        key: index
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(`${_ctx.headerColor} `)
        }, [
          _createElementVNode("button", {
            class: "w-full px-4 py-2 text-left text-lg font-semibold text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-300 overflow-auto",
            onClick: ($event: any) => (_ctx.toggle(index))
          }, [
            _createElementVNode("span", _hoisted_3, [
              _renderSlot(_ctx.$slots, "title", {}, () => [
                _createTextVNode(_toDisplayString(!_ctx.isEvent ? item.title : "Instrucciones"), 1)
              ]),
              _createVNode(_component_mdicon, {
                class: _normalizeClass(["w-5 h-5 transform transition-transform duration-200 text-white", { 'rotate-180': _ctx.isOpen(index) }]),
                name: "chevron-down",
                size: "24",
                color: "currentColor"
              }, null, 8, ["class"])
            ])
          ], 8, _hoisted_2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(`relative overflow-hidden transition-all max-h-0 duration-500 ${_ctx.bodyColor}`),
          style: _normalizeStyle({ 'max-height': _ctx.isOpen(index) ? '100vh' : '0' })
        }, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            (!_ctx.isEvent)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (Array.isArray(item.content))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (line, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(line), 1))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "px-4 py-2 text-gray-700 overflow-auto",
                        innerHTML: item.content
                      }, null, 8, _hoisted_5))
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (Array.isArray(item))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (line, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "flex flex-row flex-wrap gap-4 my-4"
                          }, [
                            _createElementVNode("strong", _hoisted_7, "Paso " + _toDisplayString(index + 1) + ": ", 1),
                            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.capitalizeFirstLetter(line.description)), 1)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
          ]),
          _renderSlot(_ctx.$slots, "footer", {}, () => [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.editable)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "flex px-4 py-2 mb-2 text-sm font-medium text-white bg-warning rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-warning",
                    onClick: ($event: any) => (_ctx.$emit('edit', index))
                  }, " Editar ", 8, _hoisted_10))
                : _createCommentVNode("", true),
              (_ctx.editable)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "flex px-4 py-2 ml-2 mr-2 mb-2 text-sm font-medium text-white bg-danger rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-danger",
                    onClick: ($event: any) => (_ctx.$emit('delete', index))
                  }, " Eliminar ", 8, _hoisted_11))
                : _createCommentVNode("", true)
            ])
          ])
        ], 6)
      ]))
    }), 128))
  ], 2))
}