
import { defineComponent, ref, computed } from "vue";
import { Users, FormattedDataResident } from "@/services/communities/types";
import TableComponent from "@/components/general/molecules/TableComponent.vue";
import { CommunityService } from "@/services/communities/CommunityServices";
import AddButton from "@/components/general/atoms/AddButton.vue";
import ModalResident from "@/components/queries/molecules/ModalResident.vue";
import { useRoute } from "vue-router";
import ModalAlert from "@/components/general/molecules/ModalAlert.vue";
import router from "@/router";
import residentsModalDetail from "@/components/queries/molecules/residentsModalDetail.vue";
import { useAuthStore } from "@/stores/auth";
import Header from "@/components/general/molecules/Header.vue";
export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const authUserCommunity = authStore.userCommunity;
    const authUser = authStore.user;
    const route = useRoute();
    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const CommunityServices = new CommunityService();
    const showModal = ref<boolean>(false);
    const userInfo = ref<Users[]>([]);
    const selectedEstablishment = ref<number>(0);
    const action = ref<string>("");
    const showModalEdit = ref<boolean>(false);
    const searchTableData = ref(["firsName", "name"]);
    const showButtons = computed(() => true);
    const numBlock = ref<string>("");
    const currentItem = ref<Users>();
    const showAlertModal = ref<boolean>(false);
    const showAlert = ref<boolean>(false);
    const message = ref<string>("");
    const loading = ref<boolean>(true);
    const selectedUnit = ref<string>("0");
    const showModalAlert = ref<boolean>(false);
    const title = ref<string>("");
    const iDinamic = ref<number>(0);
    const showResidentDetail = ref<boolean>(false);
    const selectFormattedData = ref<FormattedDataResident>();
    const establishments = [
      { value: 1, label: "Oficina" },
      { value: 2, label: "Residencia" },
      { value: 3, label: "Local Comercial" },
    ];
    const columns = ref([
      { name: "address", label: "Unidad" },
      { name: "firsName", label: "Nombre" },
      { name: "profession", label: "Profesión" },
      { name: "phone", label: "Teléfono" },
    ]);
    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/queries`,
        name: "Consultas",
      },
      { path: ``, name: "Nomina de residentes" },
    ];

    const formattedData = computed(() => {
      return userInfo.value.map((item) => {
        const data: FormattedDataResident = {
          userId: item.userId,
          position: item.position,
          parkingSpots: item.parkingSpots.map((spot) => spot.description).join(", "),
          parkingSpotsAux: item.parkingSpots,
          warehouseSpace: item.warehouses.map((spot) => spot.description).join(", "),
          wareHouse: item.warehouses,
          userRole: item.userRole,
          isActive: item.isActive,
          isCommitteMember: item.isCommitteeMember,
          committePosition: item.committeePosition,
          isFab: item.isFab,
          communityId: item.communityId,
          id: item.id,
          firsName: item.user.firstName + " " + item.user.lastName,
          lastName: item.user.lastName,
          profession: item.user.profession,
          age: item.user.age,
          phone: item.user.phone,
          hasReducedCapacity: item.user.hasReducedCapacity,
          reducedCapacityDetails: item.user.reducedCapacityDetails,
          email: item.user.email,
          rut: item.user.dni,
          userInCommunity: item.id,
          floor:
            item.communityUserUnits && item.communityUserUnits.length > 0
              ? item.communityUserUnits[0].unit.floor
              : undefined,
          address: item.communityUserUnits.map((spot) => spot.unit.address).join(", "),
          establishments:
            item.communityUserUnits && item.communityUserUnits.length > 0
              ? establishments[item.communityUserUnits[0].unit.establishmentType - 1]
                  .label
              : undefined,
          units: item.communityUserUnits,
        };
        return data;
      });
    });

    async function getCommunityResidents() {
      await CommunityServices.getResidentByBlock(communityId.value, blockId.value)
        .then((response) => {
          userInfo.value = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
      loading.value = false;
    }

    getCommunityResidents();
    function onUpdateModelUnit(value: string) {
      numBlock.value = value;
    }
    function onUpdateModelEstablishment(value: number) {
      selectedEstablishment.value = value;
    }

    function updateTable(item: any) {
      currentItem.value = userInfo.value.find((x) => x.userId === item.userId);
      showModal.value = true;
      action.value = "update";
    }
    const deleteTable = async (registro: any) => {
      iDinamic.value = registro.id;
      title.value = "¿Desea Eliminar?";
      message.value = "No será posible retroceder la Acción";
      showModalAlert.value = true;
    };
    const datailsTable = async (registro: any) => {
      showResidentDetail.value = true;
      selectFormattedData.value = registro;
      console.log(showResidentDetail.value);
    };

    const buttons = ref([
      {
        id: 1,
        name: "Editar",
        action: updateTable,
        className:
          "flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400",
        iconName: "pencil",
      },
      {
        id: 3,
        name: "Detalles",
        action: datailsTable,
        className: "bg-success text-white hover:bg-green-400 focus:bg-green-400",
        iconName: "eye-outline",
      },
    ]);

    if (authUser?.isSuperuser) {
      buttons.value.push({
        id: 2,
        name: "Eliminar",
        action: deleteTable,
        className: "bg-red-600 text-white hover:bg-red-500 focus:bg-red-500",
        iconName: "delete",
      });
    }

    function cancel() {
      showModal.value = false;
    }

    const handleCancel = () => {
      showModalAlert.value = false;
    };

    const handleDelete = async () => {
      await CommunityServices.deletePersonalService(communityId.value, iDinamic.value);
      showModalAlert.value = false;
      getCommunityResidents();
    };

    function addItem() {
      showModal.value = true;
      action.value = "create";
      console.log(showModal.value);
    }
    const handleAccept = () => {
      showModalAlert.value = false;
      showModal.value = false;
    };
    function create() {
      showModal.value = false;
      getCommunityResidents();
      console.log("creadooo");
    }
    const handleCloseModal = () => {
      showResidentDetail.value = false;
    };
    return {
      handleCancel,
      handleDelete,
      handleAccept,
      cancel,
      onUpdateModelUnit,
      onUpdateModelEstablishment,
      updateTable,
      datailsTable,
      addItem,
      create,
      handleCloseModal,
      currentItem,
      action,
      showModal,
      formattedData,
      columns,
      buttons,
      searchTableData,
      showButtons,
      establishments,
      selectedEstablishment,
      showModalEdit,
      showAlertModal,
      showAlert,
      message,
      loading,
      userInfo,
      selectedUnit,
      title,
      showModalAlert,
      showResidentDetail,
      selectFormattedData,
      breadcrumbRoutes,
    };
  },
  components: {
    TableComponent,
    ModalResident,
    ModalAlert,
    AddButton,
    residentsModalDetail,
    Header,
  },
});
