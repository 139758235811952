export interface BlockBase {
  dynamicLink: string;
  communityId: number;
  name: string;
  address: string;
  totalFloors: number;
  totalUnits: number;
  formId?: number;
  answers?: string[];
}

export class Block implements BlockBase {
  constructor(
    public dynamicLink: string,
    public id: number,
    public vehicleId: number,
    public communityId: number,
    public name: string,
    public address: string,
    public totalFloors: number,
    public totalUnits: number,
    public formId?: number,
    public answers?: string[]
  ) {}
}

export class BlockWithOutId {
  constructor(
    public community_id: number,
    public name: string,
    public address: string,
    public total_floors: number,
    public total_units: number,
    public formId?: number,
    public answers?: string[]
  ) {}
}

export class UpdateCommunityBlock {
  constructor(
    public totalVisitorParkingSpots: number,
    public totalParkingSpots: number,
    public totalWarehouseSpaces: number,
    public totalCommercialPremises: number,
    public totalUndegroundLevels: number,
    public gasType: string,
    public municipalAcceptanceDate: Date,
    public constructionYear: number,
    public structuralCalculations: string,
    public constructionCompany: string,
    public realStateAgency: string,
    public rut: string,
    public companyName: string,
    public communityId: number,
    public blockId: number,
    public name: string,
    public address: string,
    public totalFloors: number,
    public totalUnits: number
  ) {}
}

export interface BaseQuery {
  question: string;
  answer: string;
  communityId: number;
}

export class Query implements BaseQuery {
  constructor(
    public id: number,
    public question: string,
    public answer: string,
    public communityId: number
  ) {}
}
export interface FireExtinguisherBase {
  quantity: number;
  extinguisherType: number;
  maintenance: string;
  blockId: number;
}
export class FireExtinguisher implements FireExtinguisherBase {
  constructor(
    public id: number,
    public quantity: number,
    public extinguisherType: number,
    public maintenance: string,
    public blockId: number
  ) {}
}

export class FireExtinguisherUpdate {
  constructor(
    public quantity: number,
    public extinguisherType: number,
    public maintenance: string
  ) {}
}

export class FireExtinguisherDelete {
  constructor(public id: number) {}
}

export interface GasCylinderBase {
  charge: number;
  quantity: number;
  unitId: number;
}
export class GasCylinder implements GasCylinderBase {
  constructor(
    public id: number,
    public charge: number,
    public quantity: number,
    public unitId: number
  ) {}
}

export class GasCylinderUpdate {
  constructor(public charge: number, public quantity: number) {}
}

export class GasCylinderDelete {
  constructor(public id: number) {}
}

export class communitySeviceConstractor {
  constructor(
    public id: number,
    public communityId: string,
    public name: string,
    public serviceType: string,
    public contractorType: string,
    public address: string,
    public countyId: number,
    public phone: string,
    public email: string,
    public emergencyPhone: number,
    public managerName: string,
    public managerPhone: string,
    public managerEmail: string,
    public constractorTypeIcon: string,
    public contractorTypeName: string
  ) {}

  private static getConstractorTypeIcon(type: string): string {
    switch (type) {
      case "GAS":
        return "contractors/GAS";
      case "ELECTRICITY":
        return "contractors/ELECTRICITY";
      case "WATER":
        return "contractors/WATER";
      case "ELEVATORS":
        return "contractors/ELEVATORS";
      case "BOILER_ROOM":
        return "contractors/BOILER_ROOM";
      case "GENERATORS":
        return "contractors/GENERATORS";
      case "HYDROPACK":
        return "contractors/HYDROPACK";
      case "GATES":
        return "contractors/GATES";
      case "GYM":
        return "contractors/GYM";
      case "WEAK_CURRENTS":
        return "contractors/WEAK_CURRENTS";
      case "PLUMBER":
        return "contractors/PLUMBER";
      case "ELECTRICIAN":
        return "contractors/ELECTRICIAN";
      case "LOCKSMITH":
        return "contractors/LOCKSMITH";
      default:
        return "contractors/LOCKSMITH";
    }
  }

  private static getConstractorTypeName(type: string): string {
    console.log(type);
    switch (type) {
      case "GAS":
        return "GAS";
      case "ELECTRICITY":
        return "ELECTRICIDAD";
      case "WATER":
        return "AGUA POTABLE";
      case "ELEVATORS":
        return "ASCENSORES";
      case "BOILER_ROOM":
        return "CALDERA";
      case "GENERATORS":
        return "GENERADOR";
      case "HYDROPACK":
        return "HIDROPACK";
      case "GATES":
        return "PORTÓN VEHICULAR";
      case "GYM":
        return "GIMNASIO";
      case "WEAK_CURRENTS":
        return "CORRIENTES DÉBILES";
      case "PLUMBER":
        return "GASFITER";
      case "ELECTRICIAN":
        return "ELECTRICISTA";
      case "LOCKSMITH":
        return "CERRAJERO";
      case "TELECOMMUNICATIONS":
        return "TELECOMUNICACIONES";
      case "POOL":
        return "MANTENIMIENTO PISCINA";
      case "SOLAR_PANELS":
        return "PANELES SOLARES";
      case "TURNSTILES":
        return "TORNIQUETE";
      case "SAUNA":
        return "SAUNA";
      case "AUTOMATIC_BARRIERS":
        return "BARRERAS DE SEGURIDAD";
      case "GUARDS":
        return "GUARDIAS DE SEGURIDAD";
      case "GARDENER":
        return "JARDINES Y AREAS VERDES";
      default:
        return "Contratista autorizado";
    }
  }

  public static assignEventTypeIcons(
    events: communitySeviceConstractor[]
  ): void {
    events.forEach((event: communitySeviceConstractor) => {
      event.constractorTypeIcon =
        communitySeviceConstractor.getConstractorTypeIcon(event.contractorType);
    });
  }

  public static assignEventTypeName(
    events: communitySeviceConstractor[]
  ): void {
    events.forEach((event: communitySeviceConstractor) => {
      event.contractorTypeName =
        communitySeviceConstractor.getConstractorTypeName(event.contractorType);
    });
  }
}

export interface userBase {
  firstName: string;
  lastName: string;
  profession: string;
  age: number;
  email: string;
  phone: string;
  dni: string;
  hasReducedCapacity: boolean;
  reducedCapacityDetails: string;
}

export interface unitBase {
  id?: number;
  floor: number;
  address: string;
  blockId: number;
  establishmentType: number;
}
export class Unit implements unitBase {
  constructor(
    public id: number,
    public floor: number,
    public address: string,
    public blockId: number,
    public establishmentType: number
  ) {}
}
export interface userCommunityBase {
  position: string;
  userRole: number;
  isActive: boolean;
  isCommitteeMember: boolean;
  isFab: boolean;
  communityId: number;
}
export interface userCreate {
  communityUserInfo: userCommunityBase;
  userInfo: userBase;
  unitsInfo: unitBase[];
  warehouses: string[];
  parkingSpots: string[];
  unitsIds?: number[];
}
export interface unit {
  id: number;
  floor: number;
  address: string;
  blockId: number;
  establishmentType: number;
}

export class Users {
  constructor(
    public userId: number,
    public position: string,
    public parkingSpace: string,
    public warehouseSpace: string,
    public userRole: number,
    public isActive: boolean,
    public isCommitteeMember: boolean,
    public committeePosition: string,
    public isFab: boolean,
    public communityId: number,
    public id: number,
    public user: userBase,
    public isCoOwner: boolean,
    public coOwnerEmail: string,
    public coOwnerEmergencyName: string,
    public coOwnerName: string,
    public coOwnerEmergencyPhone: string,
    public communityUserUnits: [
      {
        unitId: number;
        communityUserId: number;
        id: number;
        unit: unit;
        address: string;
      }
    ],
    public parkingSpots: [
      {
        communityUserId: number;
        id: number;
        description: string;
      }
    ],
    public warehouses: [
      {
        commimunityUserId: number;
        description: string;
        id: number;
      }
    ]
  ) {}
}
export class updateUsers {
  constructor(
    public userUpdate: {
      firstName: string;
      lastName: string;
      profession: string;
      age: number;
      email: string;
      phone: string;
      hasReducedCapacity: boolean;
      reducedCapacityDetails: string;
    },
    public communityUserEdit: {
      userId: number;
      communityId: number;
      userRole: number;
      position: string;
      parkingSpace: string;
      warehouseSpace: string;
      isCommitteeMember: boolean;
      committeePosition: string;
      isFab: boolean | null;
      isCoOwner?: boolean;
      coOwnerEmail?: string;
      coOwnerEmergencyName?: string;
      coOwnerName?: string;
      coOwnerEmergencyPhone?: string;
    }
  ) // public communityUnitEdit: {
  //     unitId: number,
  //     communityUserId: number,
  //     address: string
  // }
  {}
}
export class updateUsersDiminished {
  constructor(
    public userUpdate: {
      hasReducedCapacity: boolean;
      reducedCapacityDetails: string;
    }
  ) {}
}

export class updateUserCommitte {
  constructor(
    public communityUserEdit: {
      position: string;
      isCommitteeMember: boolean;
    }
  ) {}
}

export interface userInfo {
  [x: string]: any;
  userId: number;
  position: string;
  parkingSpace: string;
  warehouseSpace: string;
  userRole: number;
  isActive: boolean;
  isCommitteeMember: boolean;
  committeePosition: string;
  isFab: boolean;
  communityId: number;
  id: number;
  user: userBase;
  units: unit[];
}

export interface vehiclesBase {
  id: number;
  patent: string;
  communityUserId: number;
}

export class WorkerInfo {
  constructor(
    public user_info: {
      firstName?: string;
      lastName?: string;
      phone?: string;
      email?: string;
    },
    public communityUserEdit: {
      position?: string;
    }
  ) {}
}

export interface CommitteUserUnit {
  userId: number;
  position: string;
  parkingSpace: string;
  warehouse_space: string;
  userTole: number;
  isActive: boolean;
  isCommitteeMember: boolean;
  committeePosition: string;
  isFab: boolean;
  communityId: number;
  id: number;
  user: User;
  units: Unit[];
}

export interface User {
  firstName: string;
  lastName: string;
  profession: string;
  age: number;
  phone: string;
  hasReducedCapacity: boolean;
  reducedCapacityDetails: string;
  email: string;
  dni: string;
  id: number;
  isSuperuser: boolean;
}

export interface Unit {
  floor: number;
  address: string;
  id: number;
  blockId: number;
  establishmentType: number;
}

export interface BlockInfo {
  address: string;
  answers: string | null;
  answersValue: string | null;
  communityId: number;
  companyName: string;
  constructionCompany: string;
  constructionYear: number;
  formId: string | null;
  gasType: string;
  municipalAcceptanceDate: string | null;
  name: string;
  realStateAgency: string;
  rut: string;
  structuralCalculations: string;
  thumbnail: string;
  totalCommercialPremises: number;
  totalFloors: number;
  totalParkingSpots: number;
  totalUndegroundLevels: number;
  totalUnits: number | 0;
  totalVisitorParkingSpots: number;
  totalWarehouseSpaces: number;
}

export interface Service {
  id: number;
  communityId: number;
  name: string;
  serviceType: string;
  contractorType: string;
  address: string;
  countyId: number;
  phone: string;
  email: string;
  emergencyPhone: string;
  managerName: string;
  managerPhone: string;
  managerEmail: string;
}

export class RegisterCompensation {
  constructor(
    public id?: number,
    public name?: string,
    public serviceType?: string,
    public communityId?: number,
    public address?: string,
    public countyId?: number,
    public phone?: string,
    public emergencyPhone?: string
  ) {}
}
export interface certificationsBase {
  certificationType: string;
  date: string;
}
export interface certificationUpdate {
  date: string;
  certificationType: string;
  blockId: number;
}
export class certifications implements certificationsBase {
  constructor(
    public id: number,
    public certificationType: string,
    public date: string
  ) {}
}
export interface insurance {
  name: string;
  address: string;
  phone: string;
  policyNumber: string;
  expirationDate: string;
  document?: File;
}
export interface updateInsurance {
  name: string;
  address: string;
  phone: string;
  policyNumber: string;
  expirationDate: string;
  communityId: number;
}
export class FormattedDataResident {
  constructor(
    public userId: number,
    public position: string,
    public parkingSpots: string,
    public warehouseSpace: string,
    public userRole: number,
    public isActive: boolean,
    public isCommitteMember: boolean,
    public committePosition: string,
    public isFab: boolean,
    public communityId: number,
    public id: number,
    public firsName: string,
    public lastName: string,
    public profession: string,
    public age: number,
    public phone: string,
    public hasReducedCapacity: boolean,
    public reducedCapacityDetails: string,
    public email: string,
    public rut: string,
    public userInCommunity: number,
    public floor?: number | undefined,
    public address?: string | undefined,
    public establishments?: string | undefined,
    public idUnit?: number | undefined,
    public units = [
      {
        unitId: 0,
        communityUserId: 0,
        id: 0,
        unit: {
          floor: 0,
          address: "",
          blockId: 0,
          establishmentType: 0,
        },
      },
    ],
    public parkingSpotsAux = [
      {
        communityUserId: 0,
        id: 0,
        description: "",
      },
    ],
    public wareHouse = [
      {
        commimunityUserId: 0,
        description: "",
        id: 0,
      },
    ]
  ) {}
}
